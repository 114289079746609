import ValidationAlert from '@/components/atoms/FormComponents/ValidationAlert/ValidationAlert.vue'
import { required } from 'vuelidate/lib/validators'

function formFieldsInitialState () {
  return {
    crewingManager: null
  }
}

export default {
  name: 'BackOfficeCrewingManagersAdd',
  components: {
    ValidationAlert
  },
  data () {
    return {
      crewingCompanyID: this.$route.params.companyID,
      dataForm: formFieldsInitialState(),
      listCrewingManagers: [],
      buttonLoader: false
    }
  },
  validations () {
    return {
      dataForm: {
        crewingManager: { required }
      }
    }
  },
  mounted () {
    this.getCrewingManagers()
  },
  methods: {
    getCrewingManagers () { // Get crewing managers how is not employed yet
      const params = new URLSearchParams({ type_user: 'crewing_manager', is_employed: 'false' })

      this.$api.get(`api/v1/crewing/manager/?${params}`).then(response => {
        if (response.code === 200) {
          response.data.results.map(item => {
            item.crewingManagerFullName = `${item.last_name} ${item.first_name} ${item.userprofile.middle_name}`
          })
          this.listCrewingManagers = response.data.results
        }
      })
    },

    checkFieldsValidation () {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else this.addNewCrewingManager()
    },

    addNewCrewingManager () {
      this.buttonLoader = true

      const body = { company_id: this.crewingCompanyID }

      this.$api.post(`api/v1/crewing/manager/${this.dataForm.crewingManager.id}/add_manager_to_company/`, { body }).then(response => {
        if (response.code === 200) {
          this.$notification.success('addedCrewingManagerToCrewing')
          this.$parent.$parent.getCrewingManagersList()
          this.$parent.viewAdd = false
          this.$data.dataForm = formFieldsInitialState()
          this.$v.$reset()
        } else if (response.code === 400) {
          if (response.data[0] === 'Given manager is already included to another crewing company') {
            this.$notification.error('crewingManagerIncludesToCrewing')
          }
        }
      }).finally(() => {
        this.buttonLoader = false
      })
    }
  }
}
