<template lang="pug">
TableV2(
  :isViewAdd="false"
  :isLoading="tableLoader"
  :items="items.results || []"
  :headers="headers"
  :pageCount="items.count"
  :currentPage="items.current"
  labelKeyAdd="addCrewingManagerToCrewing"
  :querySearch="getCrewingManagersList"
  :actions="actions")
    BackOfficeCrewingManagersAdd(slot="AddBlock")
</template>

<script>
import BackOfficeCrewingManagersAdd from '@/views/BackOffice/BackOfficeCrewing/BackOfficeCrewingManagers/BackOfficeCrewingManagersAdd/BackOfficeCrewingManagersAdd.vue'
import { checkAccess } from '@/mixins/permissions'
import { mapState } from 'vuex'
import { queryString } from '../../../../mixins/main'

export default {
  name: 'BackOfficeCrewingManagersList',
  components: {
    BackOfficeCrewingManagersAdd
  },
  data () {
    return {
      crewingCompanyID: this.$route.params.companyID,
      headers: [
        { value: 'id', text: 'ID, sortable: false' },
        { value: 'crewingManagerInitials', text: this.$t('crewingManager'), sortable: false }
      ],
      items: [],
      sortBy: 'name_ukr',
      sortDesc: false,
      tableLoader: false,
      checkAccess,
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.go',
          action: (item) => this.$router.push({ name: 'crewing-managers-backoffice-info', params: { ...this.$route.params, managerID: item.id } }),
          color: 'primary',
          name: 'mdi-arrow-right',
          isView: (item) => item.id
        }]
    }
  },
  computed: {
    ...mapState({
      userInformation: state => state.main.user
    })
  },
  mounted () {
    // this.getCrewingManagersList()

    if (checkAccess('backOffice')) {
      this.headers = [
        { value: 'id', text: 'ID' },
        { value: 'crewingManagerFullNameOffice', text: this.$t('crewingManagerFullName') },
        { value: 'managerType', text: this.$t('position') },
        { value: 'userprofile.branch_office', text: this.$t('affiliate') },
        { value: 'userprofile.city', text: this.$t('city') },
        { value: 'email', text: this.$t('email') },
        { value: 'userprofile.contact_info', text: this.$t('contacts') },
        { value: 'event', text: this.$t('actions'), align: 'center' }
      ]
    }
  },
  methods: {
    getCrewingManagersList (params) {
      this.tableLoader = true
      // const params = new URLSearchParams()
      // Set crewing company from userprofile if it's Head of crewing
      if ((checkAccess('backOffice') || checkAccess('groupHead')) && this.crewingCompanyID) {
        params.type_user = 'crewing_manager,crewing_head,secretary_service'
        params.company_id = this.crewingCompanyID
      } else {
        params.type_user = 'crewing_manager'
        params.company_id = this.userInformation.crewing_companies
      }

      this.$api.get(`api/v1/crewing/manager/` + queryString(params)).then(response => {
        if (response.code === 200) {
          response.data.results.map(manager => {
            if (manager.crewing_company) {
              manager.crewingCompany = manager.crewing_company.id
            }
            if (manager.type_user) manager.managerType = this.$t(`group-${manager.type_user}`)
          })
          this.items = response.data
          this.tableLoader = false
        }
      })
    }
  }
}

</script>
